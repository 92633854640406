
import { defineComponent, ref, onMounted, nextTick } from 'vue'
import { ErrorMessage, Field, Form } from 'vee-validate'
import * as Yup from 'yup'
import { useStore } from 'vuex'
// import { useRouter } from 'vue-router'
import { Actions } from '@/store/enums/StoreEnums'
import { PasswordMeterComponent } from '@/assets/ts/components'
import Swal from 'sweetalert2/dist/sweetalert2.min.js'
import { buildLocalResourceUrl } from '@/core/mc-admin-helpers/activity-template'

export default defineComponent({
  name: 'sign-up',
  components: {
    Field,
    Form,
    ErrorMessage
  },
  setup () {
    const store = useStore()
    // const router = useRouter()
    const phone = ref()
    const submitButton = ref<HTMLButtonElement | null>(null)
    const phoneObject = ref()
    const isSubmitPending = ref(false)
    const registration = Yup.object().shape({
      first_name: Yup.string().required().label('First name'),
      last_name: Yup.string().required().label('Last name'),
      company: Yup.string().required().label('Company'),
      email: Yup.string().min(4).required().email().label('Email'),
      password: Yup.string().required().label('Password'),
      password_confirmation: Yup.string()
        .required()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .label('Password Confirmation')
    })
    const formValidationError = ref('')
    const phoneValidationError = ref('')

    const onPhoneValidate = (phoneObj) => {
      phoneObject.value = phoneObj
      if (!phoneObj.valid && typeof phoneObj.valid !== 'undefined') {
        phoneValidationError.value = 'Phone number is not valid'
      } else {
        phoneValidationError.value = ''
      }
      console.log(phoneObj)
      // phone.value = phoneObject
    }

    onMounted(() => {
      nextTick(() => {
        PasswordMeterComponent.bootstrap()
      })
    })

    const onSubmitRegister = async (values) => {
      // Clear existing errors
      // store.dispatch(Actions.LOGOUT)
      // console.log(values)
      // console.log('phone', phone)
      // if (phone.value === '') {
      //   phoneValidationError.value = 'Phone number is not valid'
      //   return
      // }
      formValidationError.value = ''
      // eslint-disable-next-line
      submitButton.value!.disabled = true;
      isSubmitPending.value = true
      // Activate indicator
      submitButton.value?.setAttribute('data-kt-indicator', 'on')

      const payload = {} as any
      payload.firstName = values.first_name
      payload.lastName = values.last_name
      payload.label = values.company
      payload.password = values.password
      payload.email = values.email
      payload.phone = phoneObject.value?.number
      payload.countryCode = phoneObject.value?.country.iso2

      store.dispatch(Actions.REGISTER, payload).then(() => {
        isSubmitPending.value = true
        // window.location.href = '/sign-in'
        Swal.fire({
          text: 'Successfully created your account! Please check your email to verify your account.',
          icon: 'success',
          buttonsStyling: false,
          confirmButtonText: 'Okay',
          customClass: {
            confirmButton: 'btn fw-bold btn-light-success'
          }
        }).then(function () {
          window.location.href = '/sign-in'
        })
      }).catch((e) => {
        isSubmitPending.value = false
        formValidationError.value = e.data.error.message
        submitButton.value?.removeAttribute('data-kt-indicator')
            submitButton.value!.disabled = false
      })
      // Send login request
      // await store.dispatch(Actions.REGISTER, values)

      // const [errorName] = Object.keys(store.getters.getErrors)
      // const error = store.getters.getErrors[errorName]

      // if (!error) {
      //   Swal.fire({
      //     text: 'You have successfully logged in!',
      //     icon: 'success',
      //     buttonsStyling: false,
      //     confirmButtonText: 'Ok, got it!',
      //     customClass: {
      //       confirmButton: 'btn fw-bold btn-light-primary'
      //     }
      //   }).then(function () {
      //     // Go to page after successfully login
      //     router.push({ name: 'console' })
      //   })
      // } else {
      //   Swal.fire({
      //     text: error[0],
      //     icon: 'error',
      //     buttonsStyling: false,
      //     confirmButtonText: 'Try again!',
      //     customClass: {
      //       confirmButton: 'btn fw-bold btn-light-danger'
      //     }
      //   })
      // }

      submitButton.value?.removeAttribute('data-kt-indicator')
      // eslint-disable-next-line
      submitButton.value!.disabled = false;
    }

    return {
      registration,
      formValidationError,
      onSubmitRegister,
      buildLocalResourceUrl,
      onPhoneValidate,
      isSubmitPending,
      phone,
      phoneValidationError,
      submitButton
    }
  }
})
